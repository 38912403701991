import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import DOMPurify from 'dompurify';

const MessageContainer = styled.div`
    display: flex;
    justify-content: ${(props) => (props.type === 'user' ? 'flex-end' : 'flex-start')};
    align-items: center;
    margin-bottom: 10px;
`;

const MessageBubble = styled.div`
    background-color: ${(props) => (props.type === 'user' ? '#007BFF' : '#e0e0e0')};
    color: ${(props) => (props.type === 'user' ? 'white' : 'black')};
    padding: 10px;
    border-radius: 10px;
    max-width: 70%;
    min-width: 50%;
    margin-left: ${(props) => (props.type === 'user' ? '10px' : '0')};
    margin-right: ${(props) => (props.type === 'user' ? '0' : '10px')};
    word-wrap: break-word;
    // white-space: pre-wrap;
    overflow-wrap: break-word;
    line-height: 1.5;
    position: relative;
`;

const Icon = styled.img`
    width: 30px;
    height: ${(props) => (props.type === 'user' ? '28px' : '22px')};
    border-radius: 50%;
    margin-left: ${(props) => (props.type === 'user' ? '10px' : '0')};
    margin-right: ${(props) => (props.type === 'user' ? '0' : '10px')};
    align-self:self-start;
`;

const bounce = keyframes`
  0% { opacity: 0.2; transform: translateY(0); }
  20% { opacity: 1; transform: translateY(-4px); }
  40% { opacity: 0.2; transform: translateY(0); }
`;

const LoadingDots = styled.div`
  display: inline-block;
  span {
    display: inline-block;
    animation: ${bounce} 1.4s infinite both;
    margin-right: 3px;

    &:nth-child(1) {
      animation-delay: 0s;
    }
    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
    }
    &:nth-child(4) {
      animation-delay: 0.6s;
    }
  }
`;

const LittleRightCorner = styled.div`
    position: absolute;
    z-index: 10000;
    right: 35px;
    top: 0px;
    right: -5px;
    border-top: 23px solid #007BFF;
    border-right: 15px solid transparent;
    width: 0;
    height: 0;
`;

const LittleLeftCorner = styled.div`
    position: absolute;
    z-index: 10000;
    top: 0px;
    left: -5px;
    border-top: 23px solid #e0e0e0;
    border-left: 15px solid transparent;
    width: 0;
    height: 0;
`;

const ChatMessage = ({ message, type, apikey, loading }) => {

  const sanitizedHTML = DOMPurify.sanitize(message);
  const [displayMessage, setDisplayMessage] = useState(sanitizedHTML);

  const userIcon = "https://imavenwidget-ece5b15ded2b.herokuapp.com/icons/user-icon?api_key=" + apikey;
  const adminIcon = "https://imavenwidget-ece5b15ded2b.herokuapp.com/icons/admin-icon?api_key=" + apikey;

  const iconUrl = type === 'user' ? userIcon : adminIcon;

  useEffect(() => {
    if (loading) {
      setDisplayMessage('....');
    } else {
      setDisplayMessage(message);
    }
  }, [loading, message]);

  return (
    <MessageContainer type={type}>
      {type !== 'user' && <Icon src={iconUrl} alt={`${type} icon`} type={type} />}

      <MessageBubble type={type} >
        {type === 'user' && <LittleRightCorner />}
        {loading ? (
          <LoadingDots>
            <span>.</span>
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </LoadingDots>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: displayMessage }} />
        )}

        {type !== 'user' && <LittleLeftCorner />}
      </MessageBubble>
      {type === 'user' && <Icon src={iconUrl} alt={`${type} icon`} />}
    </MessageContainer>
  );
};

export default ChatMessage;
