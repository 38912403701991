import React from 'react';
import styled from 'styled-components';

const StarsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StarContainer = styled.div`
  position: relative;
  width: 20px;
  height: 100%;
  font-size: 20px;
  color: #e7e722; /* Star color */
`;

const StarFull = styled.span`
  color: #e7e722; /* Star color */
  font-size: 20px;
`;

const StarHalf = styled(StarFull)`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 50%;
`;

const StarEmpty = styled(StarFull)`
  color: rgba(231, 231, 34, 0.2);
`;

const Rating = ({ rating, reviews }) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = (rating - fullStars) >= 0.5;
    const totalStars = 5;

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>

            <div>
                <span>{rating}</span>
            </div>

            <StarsWrapper>
                {[...Array(fullStars)].map((_, index) => (
                    <StarContainer key={index}>
                        <StarFull>★</StarFull>
                    </StarContainer>
                ))}

                {hasHalfStar && (
                    <StarContainer>
                        <StarFull>★</StarFull>
                        <StarHalf>★</StarHalf>
                    </StarContainer>
                )}

                {[...Array(totalStars - fullStars - (hasHalfStar ? 1 : 0))].map((_, index) => (
                    <StarContainer key={index}>
                        <StarEmpty>★</StarEmpty>
                    </StarContainer>
                ))}
            </StarsWrapper>

            <div>
                <span>({reviews})</span>
            </div>

        </div>
    );
};

export default Rating;
