import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ChatMessage from './chat-message';
import styled from 'styled-components';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import Rating from './rating';

import LocationIcon from './assets/location-icon.svg';
import ClockIcon from './assets/clock-icon.svg';
import WebIcon from './assets/web-icon.svg';
import EmailIcon from './assets/email-icon.svg';
import PhoneIcon from './assets/phone-icon.svg';
import ImavenIcon from './assets/imaven-icon.svg'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 94vh;
  background-color: white;
`;

export const SideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 94vh;
  background-color: #F8FBFF;
`;

export const ChatWindow = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  width: 95%;
  height: 92%;
  padding: 20px;
`;

export const IFrameChatWindow = styled.div`
  background-color: white;
  border-radius: 8px;
//   box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  min-width: 93%;
  max-width: 400px;
  height: 78vh;
  padding: 20px;
`;

export const Button = styled.button`
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
`;

const SendMessageWrapper = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 12px;
    padding: 0.1rem;
`;

const IconWrapper = styled.div`
    width: 20px;
    height: 30px;
    background-color: transparent;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`

const Input = styled.input`
    border: none;
    background-color: transparent;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: gray;
    margin-left: 0.1rem;
    flex-grow: 1;

    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
    pointer-events: ${(props) => (props.a ? 'none' : 'auto')};

    &:focus {
        outline: none;
        border-color: #007BFF;
    }
`;

const MessagesContiner = styled.div`
    height: 92%;
    margin-bottom: 10px;
    overflow-y: auto;
`;

const Header = styled.div`
  height:5vh;
  width:100%;
  background-color:white;
  border-right:1px solid #B9CDD8;
  border-bottom: 1px solid #B9CDD8;
`;

const SideHeader = styled.div`
  height:5vh;
  width:100%;
//   border-right:1px solid #B9CDD8;
  border-bottom: 1px solid #B9CDD8;
`;

const IframeHeader = styled.div`
  height: 14vh;
  width: 100vw;
  background: linear-gradient(90deg, #004c99, #009BF3);
  color: white;
  border-radius: 10px 10px 0px 0px;
  display:flex;
  flex-direction: column;
  justify-content: space-around;
`;

const LogoWrapper = styled.div`
    // width: 50px;
    height: 100%;
    position: relative;
    top: var(--logo-vertical-position);
    display: flex;
    align-items: center;
`

const Logo = styled.div`
    background-image: url(${(props) => props.url});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 50px;
    height: 100%;
`;

const Footer = styled.div`
    height:10vh;
    width:100vw;
    display: flex;
    justify-content: right;
    align-items: center;
    background-color:red;
`

const SideFooter = styled.div`
    height:10vh;
    width:100%;
    display: flex;
    justify-content: right;
    align-items: center;
    background-color:#F8FBFF;
`

const PoweredByWrapper = styled.div`
    width: 100%;
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    display:flex;
    justify-content:flex-end;
`

const PoweredBy = styled.a`
    display: flex;
    align-items: center;
`

const PowerByText = styled.span`
    margin: 0 5px;
`;

const ExitButtonWrapper = styled.div`
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 5px;
`

const LoadingOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 9999;
`;

const FadeInWrapper = styled.div`
    opacity: ${(props) => (props.loaded ? 1 : 0)};
    transition: opacity 4s ease-in-out;
`;

const ExpertImageWrapper = styled.div`
    width:100%;
    height:247px;
`

const ExpertTitle = styled.div`
    border-bottom: 1px solid #B9CDD8;
    height: 150px;
    width: 100%;

    font-family: Helvetica Neue LT;
    font-size: 24px;
    font-weight: 300;
    line-height: 30.5px;
    text-align: left;
`

const ExpertInfo = styled.div``

const InfoContainer = styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Text = styled.div`
  font-size: 16px;
`;

const HighlightedText = styled.span`
  color: green; /* Special styling for "Open" text */
`;

const GrayText = styled.span`
  color: #777;
`;

const InfoIconWrapper = styled.div`
  margin-right: 10px;
  font-size: 20px;
  color: #555;
`

const createMessagesHash = (messages) => messages.map(msg => msg.message).join('|');

const ChatPage = () => {

    const { apikey } = useParams();
    const [messages, setMessages] = useState([]);
    const [currentMessage, setCurrentMessage] = useState("");
    const [error, setError] = useState("");
    const [isIframe, setIsIframe] = useState(true);
    const listRef = useRef(null);
    const inputRef = useRef(null);
    const uploadRef = useRef(null);
    const [loadingMessage, setLoadingMessage] = useState(false);
    const [loading, setLoading] = useState(true);
    const [emojiPickerOn, setEmojiPickerOn] = useState(false);
    const [expertData, setExpertData] = useState(
        {
            expertName: '',
            imgUrl: '',
            expertTilte: '',
            stars: 0,
            reviewAmount: 0,
            expertInfo: {
                location: "",
                hourClose: "",
                site: "",
                email: "",
                phone: ""
            },
        })
    // const base_url = "https://imavenwidget-ece5b15ded2b.herokuapp.com";
    const base_url = "https://urchin-app-t9sq7.ondigitalocean.app"


    useEffect(() => {
        setIsIframe(window.self !== window.top);
    }, []);

    useEffect(() => {
        if (apikey) {
            const fetchData = async () => {
                try {
                    const response = await fetch(base_url + '/chat/start', {
                        method: 'GET', headers: {
                            'x-apikey': apikey
                        }
                    });
                    if (!response.ok) {
                        // const data = await response.json();
                        throw "Network response was not ok";
                    }

                    setMessages([])
                    const result = await response.json();
                    localStorage.setItem('imavenconvoid', result.response)
                    localStorage.setItem('threadid', 'none');
                    localStorage.setItem('asstId', 'asst_5WqDk3WVgQCoOlJO18quwhlh');
                    setExpertData(result.expertData)
                } catch (err) {
                    setError(err);
                } finally {
                    setLoading(false)
                }
            };

            fetchData();
        }

    }, []);

    const messagesHash = createMessagesHash(messages);

    useEffect(() => {
        if (listRef && listRef.current && !isIframe) {
            setTimeout(() => {
                listRef.current.scrollTop = listRef.current.scrollHeight;
            }, 10);
        }
    }, [messagesHash, isIframe]);

    const sendMessage = () => {

        if (currentMessage.trim().length < 2)
            return

        setLoadingMessage(true)
        const newMessage = { message: currentMessage, type: 'user' };
        let temp = [...messages, newMessage]
        setMessages(temp)
        setCurrentMessage("")


        const gptMessage = { message: "", type: 'gpt' };
        temp.push(gptMessage)
        setMessages(temp)

        fetch(base_url + '/chat/sendMessage', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Conversation-ID': localStorage.getItem('imavenconvoid'),
                'X-Thread-ID': localStorage.getItem('threadid'),
                'X-Asst-ID': localStorage.getItem('asstId'),
                'x-apikey': apikey
            },
            body: JSON.stringify({ message: currentMessage })
        })
            .then(async response => {
                const data = await response.json();
                if (!response.ok) {
                    throw new Error(data.message || 'Error sending message. Please try again.');
                }
                return data;
            })
            .then(res => {
                localStorage.setItem('threadid', res.thread_id);
                localStorage.setItem('asstId', res.asstId);
                updateLastMessage(res.response)

                inputRef.current.focus();

                if (res.finishFlag) {
                    const lastUrl = `<div>
                                <span>Please enter the link to finish:</span>
                                <br/>
                                <a href="http://localhost:3333/${res.id}/${res.thread_id}" target="_blank" rel="noopener noreferrer">
                                    Click Me
                                </a>
                            </div>`;

                    const lastMessage = { message: lastUrl, type: 'gpt' };

                    setMessages((prevMessages) => [...prevMessages, lastMessage]);
                }

            })
            .catch(error => {
                console.log(error);
                updateLastMessage(error)
            })
            .finally(() => {
                setLoadingMessage(false)
            })
    }

    const updateLastMessage = (message) => {
        setMessages((prevMessages) => {
            const newMessages = [...prevMessages];
            newMessages[newMessages.length - 1] = {
                ...newMessages[newMessages.length - 1],
                message: message
            };
            return newMessages;
        });
    }

    const handleInputChange = (event) => {
        setCurrentMessage(event.target.value);
    }

    const uploadDoc = () => {
        uploadRef.current.click()
    }

    const handleFileChange = async (event) => {
        setLoadingMessage(true);
        try {
            const files = event.target.files;
            if (files.length === 0)
                return;

            const formData = new FormData();
            let fileNames = [];

            for (let i = 0; i < files.length; i++) {
                formData.append('files', files[i]);
                fileNames.push(files[i].name);  // Collect file names
            }

            const userMessage = `File uploaded: ${fileNames.join(", ")}`;
            const newMessage = { message: userMessage, type: 'user' };
            let temp = [...messages, newMessage];
            setMessages(temp);

            const response = await fetch(base_url + '/upload/', {
                method: 'POST',
                body: formData,
                credentials: 'include',
                headers: {
                    'X-Conversation-ID': localStorage.getItem('imavenconvoid'),
                    'X-Thread-ID': localStorage.getItem('threadid'),
                }
            });

            if (response.ok) {
                const gptMessage = { message: "", type: 'gpt' };
                temp.push(gptMessage);
                setMessages(temp);
                setLoadingMessage(true);

                const aiResponse = await fetch(base_url + '/upload/sendFileData', {
                    body: JSON.stringify({ flag: true }),
                    method: 'POST',
                    headers: {
                        'X-Conversation-ID': localStorage.getItem('imavenconvoid'),
                        'X-Thread-ID': localStorage.getItem('threadid'),
                        'Content-Type': 'application/json',
                    }
                });

                const data = await aiResponse.json();
                updateLastMessage(data.response);
            }
        } catch (error) {
            console.log("File upload error: ", error);
            updateLastMessage(error);
        } finally {
            setLoadingMessage(false);
        }
    };

    const closeChat = () => {
        window.parent.postMessage({ action: 'closeChat' }, '*');
    };

    const openEmojiPicker = () => {
        setEmojiPickerOn(!emojiPickerOn)
    }

    const emojiSelected = (event) => {
        console.log(event);
        setCurrentMessage(currentMessage + event.native)
    }

    return (
        <>
            {
                loading ? (
                    <LoadingOverlay>
                        <img src="https://example.com/loading.gif" alt="Loading..." />
                    </LoadingOverlay>
                ) : (
                    <FadeInWrapper loaded={!loading}>
                        {/* onClickOutside={openEmojiPicker} */}
                        {emojiPickerOn &&
                            <div style={{ top: '32%', position: 'absolute', left: "12%" }}>
                                <Picker data={data} onEmojiSelect={emojiSelected} theme="light" />
                            </div>
                        }
                        {
                            error.length > 0 ? (
                                <div>
                                    {error}
                                </div>
                            ) : (isIframe ? (
                                <div style={{ width: "320px", borderRadius: "12px", height: '600px' }}>
                                    <IframeHeader>
                                        <div style={{
                                            width: '100%', display: 'flex',
                                            justifyContent: 'space-between', alignItems: 'center'
                                        }}>
                                            <LogoWrapper>
                                                <Logo url={ImavenIcon}></Logo>
                                                <span>We're Here to Help!</span>
                                            </LogoWrapper>
                                            <ExitButtonWrapper onClick={closeChat}>
                                                <img src={`https://imavenwidget-ece5b15ded2b.herokuapp.com/icons/exist-icon?api_key=${apikey}`} />
                                            </ExitButtonWrapper>
                                        </div>
                                        <div style={{ width: '100%', borderTop: "1px solid #cccccc" }}>
                                            <span>Instant AI assistance.</span>
                                        </div>
                                    </IframeHeader>
                                    <IFrameChatWindow>

                                        <MessagesContiner ref={listRef}>
                                            {messages.map((item, index) => (
                                                <ChatMessage message={item.message} type={item.type} apikey={apikey}
                                                    key={index} loading={loadingMessage && index === messages.length - 1} />
                                            ))}
                                        </MessagesContiner>

                                        <SendMessageWrapper>
                                            <input name="files" type="file" id="file-input" style={{ display: 'none' }}
                                                ref={uploadRef}
                                                accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.docx,.doc,.xlsx,.xls,.pptx,.ppt,.txt,.html,.md,.csv,.json,.epub"
                                                multiple onChange={handleFileChange} />

                                            <IconWrapper disabled={loadingMessage} onClick={uploadDoc}>
                                                <img src={`https://imavenwidget-ece5b15ded2b.herokuapp.com/icons/upload-icon?api_key=${apikey}`}
                                                    alt="upload icon" />
                                            </IconWrapper>

                                            <IconWrapper disabled={true} onClick={openEmojiPicker}>
                                                <img src={`https://imavenwidget-ece5b15ded2b.herokuapp.com/icons/emoji-icon?api_key=${apikey}`}
                                                    alt="emoji icon" />
                                            </IconWrapper>

                                            <Input ref={inputRef} placeholder='Type your message...' type='text'
                                                value={currentMessage} onChange={handleInputChange} disabled={loadingMessage}
                                                onKeyDown={(e) => e.key === 'Enter' && !loadingMessage && sendMessage()} />

                                            <IconWrapper style={{ marginRight: '5px' }} onClick={sendMessage} disabled={loadingMessage}>
                                                <img src={`https://imavenwidget-ece5b15ded2b.herokuapp.com/icons/send-btn?api_key=${apikey}`}
                                                    alt="send icon" />
                                            </IconWrapper>
                                        </SendMessageWrapper>

                                        <PoweredByWrapper>
                                            <PoweredBy href="https://imavenai.com/" target="_blank" rel="noopener noreferrer">
                                                <PowerByText>POWERED BY</PowerByText>
                                                <img src={base_url + `/icons/small-logo?api_key=${apikey}`} alt="IMAVEN Logo"
                                                    width={'25px'} height={'20px'} />
                                                <PowerByText>IMAVEN</PowerByText>
                                            </PoweredBy>
                                        </PoweredByWrapper>

                                    </IFrameChatWindow>
                                </div>
                            ) : (
                                <div style={{ display: 'flex', flexDirection: 'row' }}>

                                    <div style={{ width: '85%' }}>
                                        <Header>
                                            {/* <LogoWrapper>
                                                <Logo url={"https://imavenai.com/Resources/icon.svg"}></Logo>
                                            </LogoWrapper> */}
                                        </Header>

                                        <Container>
                                            <ChatWindow>

                                                <MessagesContiner ref={listRef}>
                                                    {messages.map((item, index) => (
                                                        <ChatMessage message={item.message} type={item.type} apikey={apikey}
                                                            key={index} loading={loadingMessage && index === messages.length - 1} />
                                                    ))}
                                                </MessagesContiner>

                                                <SendMessageWrapper>
                                                    <input name="files" type="file" id="file-input" style={{ display: 'none' }}
                                                        ref={uploadRef}
                                                        accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.docx,.doc,.xlsx,.xls,.pptx,.ppt,.txt,.html,.md,.csv,.json,.epub"
                                                        multiple onChange={handleFileChange} />

                                                    <IconWrapper disabled={loadingMessage} onClick={uploadDoc}>
                                                        <img src={`https://imavenwidget-ece5b15ded2b.herokuapp.com/icons/upload-icon?api_key=${apikey}`}
                                                            alt="upload icon" />
                                                    </IconWrapper>

                                                    <IconWrapper disabled={loadingMessage} onClick={openEmojiPicker}>
                                                        <img src={`https://imavenwidget-ece5b15ded2b.herokuapp.com/icons/emoji-icon?api_key=${apikey}`}
                                                            alt="emoji icon" />
                                                    </IconWrapper>

                                                    <Input ref={inputRef} placeholder='Type your message...' type='text'
                                                        value={currentMessage} onChange={handleInputChange} disabled={loadingMessage}
                                                        onKeyDown={(e) => e.key === 'Enter' && !loadingMessage && sendMessage()} />

                                                    <IconWrapper style={{ marginRight: '5px' }} onClick={sendMessage} disabled={loadingMessage}>
                                                        <img src={`https://imavenwidget-ece5b15ded2b.herokuapp.com/icons/send-btn?api_key=${apikey}`}
                                                            alt="send icon" />
                                                    </IconWrapper>
                                                </SendMessageWrapper>

                                            </ChatWindow>
                                        </Container>
                                    </div>

                                    <div style={{ width: '15%' }}>
                                        <SideHeader>
                                            {/* <LogoWrapper>
                                                <Logo url={"https://imavenai.com/Resources/icon.svg"}></Logo>
                                            </LogoWrapper> */}
                                        </SideHeader>

                                        <SideContainer>

                                            {/* image */}
                                            <ExpertImageWrapper>
                                                <img src={expertData.imgUrl} width={'100%'} height={'100%'} alt='expert image' />
                                            </ExpertImageWrapper>

                                            <ExpertTitle>
                                                <div style={{ height: '100%', padding: '20px' }}>
                                                    <span>
                                                        {expertData.expertName}
                                                    </span>
                                                    <br />
                                                    <span>
                                                        {expertData.expertTilte}
                                                    </span>
                                                    <br />
                                                    <br />
                                                    <Rating rating={expertData.stars} reviews={expertData.reviewAmount} ></Rating>
                                                </div>
                                            </ExpertTitle>

                                            <InfoContainer>
                                                <ContactItem>
                                                    <InfoIconWrapper>
                                                        <img src={LocationIcon} width={'25px'} alt='location icon' />
                                                    </InfoIconWrapper>
                                                    <Text>{expertData.expertInfo.location}</Text>
                                                </ContactItem>

                                                <ContactItem>
                                                    <InfoIconWrapper>
                                                        <img src={ClockIcon} alt='clock icon' />
                                                    </InfoIconWrapper>
                                                    <Text>
                                                        <HighlightedText>Open</HighlightedText> · Closes {expertData.expertInfo.hourClose}
                                                    </Text>
                                                </ContactItem>

                                                <ContactItem>
                                                    <InfoIconWrapper>
                                                        <img src={WebIcon} alt='web icon' />
                                                    </InfoIconWrapper>
                                                    <Text>{expertData.expertInfo.site}</Text>
                                                </ContactItem>

                                                <ContactItem>
                                                    <InfoIconWrapper>
                                                        <img src={EmailIcon} alt='email icon' />
                                                    </InfoIconWrapper>
                                                    <Text>{expertData.expertInfo.email}</Text>
                                                </ContactItem>

                                                <ContactItem>
                                                    <InfoIconWrapper>
                                                        <img src={PhoneIcon} alt='phone icon' />
                                                    </InfoIconWrapper>
                                                    <Text>{expertData.expertInfo.phone}</Text>
                                                </ContactItem>
                                            </InfoContainer>


                                        </SideContainer>

                                    </div>
                                </div>
                            ))

                        }
                    </FadeInWrapper>
                )
            }
        </>
    );
};

export default ChatPage;
